// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["sfTVcIpC0", "D8od6zCli"];

const variantClassNames = {D8od6zCli: "framer-v-c8k6w5", sfTVcIpC0: "framer-v-1sod6e3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "sfTVcIpC0", "Variant 2": "D8od6zCli"}

const getProps = ({height, id, link2, width, ...props}) => { return {...props, ogz6Os411: link2 ?? props.ogz6Os411, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sfTVcIpC0"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ogz6Os411, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sfTVcIpC0", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter6qeeg8 = activeVariantCallback(async (...args) => {
setVariant("D8od6zCli")
})

const onMouseLeavee8gcyf = activeVariantCallback(async (...args) => {
setVariant("sfTVcIpC0")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mFyNt", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={ogz6Os411}><motion.a {...restProps} className={`${cx("framer-1sod6e3", className)} framer-1skjy8y`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"sfTVcIpC0"} onMouseEnter={onMouseEnter6qeeg8} ref={ref} style={{backgroundColor: "var(--token-67fbec5f-f86d-4a68-8709-0a9a96756c72, rgba(255, 255, 255, 0.1))", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} {...addPropertyOverrides({D8od6zCli: {"data-framer-name": "Variant 2", onMouseEnter: undefined, onMouseLeave: onMouseLeavee8gcyf}}, baseVariant, gestureVariant)}><motion.div className={"framer-jibnts-container"} layoutDependency={layoutDependency} layoutId={"O01tlcBtS-container"}><Phosphor color={"var(--token-7cfab920-9d1e-4f38-8c7a-a58d533b8508, rgb(255, 255, 255)) /* {\"name\":\"White 100%\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"InstagramLogo"} id={"O01tlcBtS"} layoutId={"O01tlcBtS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({D8od6zCli: {color: "var(--token-9f0badbf-8d74-41b8-a73a-e1a6bdec487b, rgb(255, 132, 66)) /* {\"name\":\"Orange\"} */", weight: "duotone"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mFyNt [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mFyNt .framer-1skjy8y { display: block; }", ".framer-mFyNt .framer-1sod6e3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 40px; }", ".framer-mFyNt .framer-jibnts-container { flex: none; height: 26px; position: relative; width: 26px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mFyNt .framer-1sod6e3 { gap: 0px; } .framer-mFyNt .framer-1sod6e3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mFyNt .framer-1sod6e3 > :first-child { margin-left: 0px; } .framer-mFyNt .framer-1sod6e3 > :last-child { margin-right: 0px; } }", ".framer-mFyNt.framer-v-c8k6w5 .framer-jibnts-container { height: 32px; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"D8od6zCli":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ogz6Os411":"link2"}
 */
const FramerN1XO80tK0: React.ComponentType<Props> = withCSS(Component, css, "framer-mFyNt") as typeof Component;
export default FramerN1XO80tK0;

FramerN1XO80tK0.displayName = "Icon_Instagram";

FramerN1XO80tK0.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerN1XO80tK0, {variant: {options: ["sfTVcIpC0", "D8od6zCli"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, ogz6Os411: {title: "Link 2", type: ControlType.Link}} as any)

addFonts(FramerN1XO80tK0, [...PhosphorFonts])